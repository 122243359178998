import React from 'react';
import className from 'classnames';
import { HorizontalScroll, Tabs } from '@vkontakte/vkui';
import styles from './styles.module.scss';
const AnchorTabs = (props) => {
    const { children, mode = 'secondary', variant = 'plain', ...tabsProps } = props;
    const isStickyMode = variant === 'sticky';
    return (<Tabs {...tabsProps} className={className(tabsProps.className, styles.anchorTabs, isStickyMode && styles.sticky)} mode={mode}>
      <HorizontalScroll showArrows={false} inline={true}>
        {children}
      </HorizontalScroll>
    </Tabs>);
};
export default AnchorTabs;
