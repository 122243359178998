import React, { useEffect, useState } from 'react';
import { useGate, useUnit } from 'effector-react';
import { useIntl } from 'react-intl';
import { ApplicationBlock, FaqAccordion, FeaturesBlock } from '@common/widgets/landings-promo/neon-design';
import { META_TAGS } from '@website/template/main-template/lib/meta-tags';
import { MainLayout } from '@website/widgets/layouts';
import { ShowcaseOffersWrapper } from '@website/widgets/showcase-offers-wrapper';
import { AppLinksBlock } from '@website/widgets/landings/app-links-block';
import HeroUniversal from '@website/widgets/landings/hero/hero-universal/hero-universal';
import showSkeleton from '@website/widgets/landings/hero/hero-universal/helpers/showSkeleton';
import { MainTemplateModel } from '@website/template/main-template/models';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { OfferBlockQ32024 } from 'common/widgets/offer-block-q3-2024';
import { faq } from './lib/faq';
import styles from './style.module.scss';
import { OffersModel } from './lib/offers-model';
import { applyOfferWebsiteFactory } from '@website/features/apply-offer/lib';
import { messages } from './messages';
import { showcaseOffersABTestQ32024Model } from '@website/template/main-template/models/ab-test-q3-2024-model';
import { OFFERS_KEY_WORD_MAIN_WEBSITE } from '@website/shared/constants/website-keywords';
import { HeroImage } from '@website/template/main-template/ui/hero-image';
const MainTemplate = () => {
    useGate(MainTemplateModel.MainGate);
    const intl = useIntl();
    const DEFAULT_TITLE = intl.formatMessage(messages.hero_title_default, { br: `<br />` });
    const offersState = useUnit(OffersModel.$offersState);
    const offerHero = useUnit(OffersModel.$offerHero);
    const showcaseOffers = useUnit(OffersModel.$showcaseOffers);
    const abTestShowcaseOffer = useUnit(showcaseOffersABTestQ32024Model.$checkConditionABTestEnableResult);
    const [title, setTitle] = useState('');
    const hasActiveSubscriptionStatus = useUnit(SubscriptionsModel.$hasActiveSubscriptionStatus);
    const hero = offerHero?.widgets.hero;
    const description = hero?.description ?? '';
    const subtitle = hero?.subtitle ?? '';
    const applyOffer = applyOfferWebsiteFactory({ skipCheckSubscription: true });
    let offersBlock = null;
    if (showcaseOffers.length > 0) {
        if (abTestShowcaseOffer === OFFERS_KEY_WORD_MAIN_WEBSITE) {
            offersBlock = (<ShowcaseOffersWrapper offersState={offersState} offers={showcaseOffers} applyOffer={applyOffer}/>);
        }
        else {
            offersBlock = (<OfferBlockQ32024 offers={showcaseOffers} offerGroupListClassName={styles.offersGroup} applyOffer={applyOffer}/>);
        }
    }
    useEffect(() => {
        if (hero) {
            setTitle(hero?.title ?? DEFAULT_TITLE);
        }
    }, [hero]);
    return (<MainLayout title={intl.formatMessage(messages.layout_title)} metaTags={META_TAGS}>
      <HeroUniversal className={styles.hero} offer={offerHero} offersState={offersState} title={title} subtitle={subtitle} description={description} hasActiveSubscriptionStatus={hasActiveSubscriptionStatus} showSkeleton={showSkeleton({ offersState })} image={<HeroImage />}/>
      <div className={'cui-spacing__promo-block-default'}/>
      <FeaturesBlock />
      <div className={'cui-spacing__promo-block-default'}/>
      {offersBlock}
      <div className={'cui-spacing__promo-block-default'}/>
      <ApplicationBlock />
      <div className={'cui-spacing__promo-block-default'}/>
      <FaqAccordion content={faq}/>
      <div className={'cui-spacing__promo-block-default'}/>
      <AppLinksBlock />
    </MainLayout>);
};
export default MainTemplate;
