import React, { useEffect, useState } from 'react';
import className from 'classnames';
import { TabsItem } from '@vkontakte/vkui';
import styles from './styles.module.scss';
import { useAnchorTabsContext } from '@common/shared/ui/anchor-tabs/lib';
const AnchorTab = (props) => {
    const { anchorId, children, ...tabsItemProps } = props;
    const anchorTabsContext = useAnchorTabsContext();
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        setSelected(Boolean(tabsItemProps.selected));
    }, [tabsItemProps.selected]);
    useEffect(() => {
        if (!anchorTabsContext) {
            return;
        }
        const { activeTabId } = anchorTabsContext;
        setSelected(activeTabId === anchorId);
    }, [anchorTabsContext.activeTabId]);
    const onClick = (event) => {
        tabsItemProps.onClick && tabsItemProps.onClick(event);
        const content = document.getElementById(anchorId);
        if (!content) {
            return;
        }
        if (anchorTabsContext && anchorTabsContext.handleClickAnchorTab) {
            anchorTabsContext.handleClickAnchorTab(content);
        }
        const coords = content.getBoundingClientRect();
        const hasTitle = content.getElementsByTagName('h3').length > 0;
        const padding = hasTitle ? 110 : 150;
        window.scrollTo({ top: coords.top + window.scrollY - padding, behavior: 'smooth' });
    };
    return (<TabsItem {...tabsItemProps} selected={selected} className={className(tabsItemProps.className, styles.anchorTab)} onClick={onClick}>
      {children}
    </TabsItem>);
};
export default AnchorTab;
