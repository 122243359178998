import React from 'react';
import { Benefit } from '../benefit';
import styles from './styles.module.scss';
const BenefitList = (props) => {
    const { benefits } = props;
    return (<ul className={styles.benefitList}>
      {benefits.map((benefit) => (<Benefit key={benefit}>{benefit}</Benefit>))}
    </ul>);
};
export default BenefitList;
