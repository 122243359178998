import React from 'react';
import { useAnchorTabsContext } from '@common/shared/ui/anchor-tabs/lib';
const AnchorTabContent = (props) => {
    const { subscribe } = useAnchorTabsContext();
    const { children, anchorId, ...generalProps } = props;
    return (<div id={anchorId} ref={(instance) => subscribe && subscribe(instance)} {...generalProps}>
      {children}
    </div>);
};
export default AnchorTabContent;
