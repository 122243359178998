import { attach, createEffect, createStore } from 'effector';
import apiCommon from '@common/utils/ApiCommon';
const $featureFlags = createStore(null);
const getFeatureFlagsFx = attach({
    effect: createEffect(async (featureFlags) => {
        if (featureFlags) {
            return featureFlags;
        }
        const { data } = await apiCommon.getFeatureFlags();
        return data;
    }),
    source: $featureFlags,
    mapParams: (_, states) => states,
});
$featureFlags.on(getFeatureFlagsFx.doneData, (_, result) => {
    return result;
});
export const featureFlagsModel = {
    getFeatureFlagsFx,
    $featureFlags,
};
