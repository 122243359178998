import React, { useEffect, useState } from 'react';
import { AnchorTabsContext } from '@common/shared/ui/anchor-tabs/lib';
const observersMap = new WeakMap();
export const AnchorScrollObserver = (props) => {
    const { children, defaultActiveId = '' } = props;
    const { ...observerProps } = props.observerProps ?? {};
    const [activeTab, setActiveTab] = useState(defaultActiveId);
    const [subscribedContentList, setSubscribedContentList] = useState(new Set());
    const [clickedToTargetElement, setClickedToTargetElement] = useState(null);
    const subscribe = (element) => {
        if (!element) {
            return;
        }
        if (subscribedContentList.has(element)) {
            return;
        }
        setSubscribedContentList((prevState) => {
            return new Set(prevState.add(element));
        });
    };
    const handleClickAnchorTab = (value) => {
        setClickedToTargetElement(value);
    };
    useEffect(() => {
        subscribedContentList.forEach((item) => {
            const rationViewportToHeightElement = document.body.clientHeight / item.clientHeight;
            const roundedRationViewportToHeightElement = rationViewportToHeightElement > 1 ? 1 : Number(rationViewportToHeightElement.toFixed(2));
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(({ isIntersecting, target }) => {
                    const id = target.id;
                    if (isIntersecting) {
                        if (clickedToTargetElement && clickedToTargetElement === target && id === clickedToTargetElement.id) {
                            setActiveTab(id);
                            setClickedToTargetElement(null);
                            return;
                        }
                        if (!clickedToTargetElement) {
                            setActiveTab(id);
                        }
                    }
                });
            }, { ...observerProps, threshold: roundedRationViewportToHeightElement });
            observer.observe(item);
            observersMap.set(item, observer);
        });
        return () => {
            subscribedContentList.forEach((item) => {
                const observer = observersMap.get(item);
                if (!observer) {
                    return;
                }
                observer.disconnect();
                observersMap.delete(item);
            });
        };
    }, [subscribedContentList, observerProps, clickedToTargetElement]);
    return (<AnchorTabsContext.Provider value={{ subscribe, activeTabId: activeTab, handleClickAnchorTab }}>
      {children}
    </AnchorTabsContext.Provider>);
};
export default AnchorScrollObserver;
