import React from 'react';
import cn from 'classnames';
import { analyticsDictionary, createAnalyticAttrs, createAnalyticShowAttribute } from '@analytics';
import { Icon12ArrowDown } from '@vkontakte/icons';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { HeroSkeleton } from '@website/widgets/landings/hero/hero-universal/ui/skeleton';
import style from './style.module.scss';
import { ApplyOfferButton } from '@website/features/apply-offer';
import { typografIt } from '@common/components/common/Typograf';
import { classNames } from '@vkontakte/vkui';
const HeroUniversal = ({ offer, offersState, title, hasActiveSubscriptionStatus, subtitle = '', description = '', showDescription = true, image, button, showSkeleton, className, }) => {
    const defaultTitle = ' <br> <br> ';
    let resultButton;
    if (button) {
        resultButton = button;
    }
    else {
        resultButton = (<ApplyOfferButton offer={offer || null} offersState={offersState || 'success'} type='hero' {...createAnalyticAttrs(analyticsDictionary.landingHeroGoToLkButtonClick, ['click'])} {...createAnalyticShowAttribute(analyticsDictionary.landingHeroGoToLkButtonClick)}/>);
    }
    return (<ContentLayout className={cn(className, style.hero)} {...createAnalyticAttrs(analyticsDictionary.landingHeroShow, ['show'])}>
      {showSkeleton ? (<HeroSkeleton image={image}/>) : (<>
          <div id={'js-btn-container'}>
            <div className={style.container}>
              <div className={style.content}>
                <h1 className={classNames(style.title, 'cui-title__promo-default')} dangerouslySetInnerHTML={{
                __html: title || defaultTitle,
            }}/>
                <p className={style.subtitle} dangerouslySetInnerHTML={{
                __html: (!hasActiveSubscriptionStatus && typografIt(subtitle)) || '',
            }}/>
              </div>
              <div className={style.imageContainer}>{image}</div>
            </div>
            {resultButton}
          </div>
          {showDescription && (<p className={style.description} dangerouslySetInnerHTML={{
                    __html: (!hasActiveSubscriptionStatus && typografIt(description)) || '',
                }}/>)}
        </>)}
      <div className={style.navigation}>
        <Icon12ArrowDown />
        Подробнее о&nbsp;подписке
      </div>
    </ContentLayout>);
};
export default HeroUniversal;
