import React from 'react';
import { Icon16CheckOutline } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
const Benefit = (props) => {
    const { children } = props;
    return (<li className={styles.benefit}>
      <div className={styles.check}>
        <Icon16CheckOutline />
      </div>
      <Typograf>{children}</Typograf>
    </li>);
};
export default Benefit;
