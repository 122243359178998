import React, { useState } from 'react';
import { Icon24Education, Icon24Hearts2, Icon24User, Icon24Video } from '@vkontakte/icons';
import { createAnalyticClickAttribute, createAnalyticShowAttribute, getDefaultMetrics } from '@analytics';
import cn from 'classnames';
import { AnchorScrollObserver, AnchorTab, AnchorTabContent, AnchorTabs } from '@common/shared/ui/anchor-tabs';
import styles from './styles.module.scss';
import { OfferCardQ32024 } from '@common/entities/offers/ui/offer-card-q3-2024';
import { Button } from '@common/shared/ui';
const IconsMap = {
    icon_vk_24_education: <Icon24Education />,
    icon_vk_24_video: <Icon24Video />,
    icon_vk_24_user: <Icon24User />,
    icon_vk_24_hearts_2: <Icon24Hearts2 />,
};
const getTabIcons = (iconId) => {
    if (!iconId) {
        return;
    }
    return IconsMap[iconId];
};
const OfferBlockQ32024 = (props) => {
    const { offers, applyOffer } = props;
    const [activeTab, setActiveTab] = useState('mono');
    const [loadingButton, setLoadingButton] = useState(false);
    const showCaseOffers = offers.map((offer) => ({
        ...offer.widgets.showcaseOffer,
        ...offer,
    }));
    const tabsMap = new Map();
    const groupOfferByTab = new Map();
    showCaseOffers.forEach((offer) => {
        const { tab } = offer;
        if (!tab) {
            return;
        }
        if (!tabsMap.has(tab.id)) {
            tabsMap.set(tab.id, tab);
        }
        if (groupOfferByTab.has(tab.id)) {
            const prevValue = groupOfferByTab.get(tab.id);
            if (!prevValue) {
                return;
            }
            groupOfferByTab.set(tab.id, [...prevValue, offer]);
            return;
        }
        groupOfferByTab.set(tab.id, [offer]);
    });
    const tabsList = [...tabsMap.values()];
    const groupShowCaseOfferList = [...groupOfferByTab.entries()];
    const clickSetActiveTab = (id) => {
        setActiveTab(id);
    };
    const button = (button, offer) => {
        const buttonApply = button ?? { type: 'purchase' };
        const onClick = async () => {
            setLoadingButton(true);
            try {
                await applyOffer({
                    correlationId: offer.correlationId,
                    button: buttonApply,
                    offerName: offer?.badge?.title ?? '',
                    subscriptionType: offer?.with.subscriptionType,
                    callbackAfterFinishedFlow: () => setLoadingButton(false),
                });
            }
            catch {
                setLoadingButton(false);
            }
        };
        return (<Button mode={button?.theme === 'default' ? 'secondary' : 'primary'} stretched={true} description={button?.description} loading={loadingButton} disabled={loadingButton} onClick={onClick} {...createAnalyticClickAttribute(getDefaultMetrics(button?.metrics, 'click', 'landing-showcase-offers-widget-button'))}>
        {button?.name}
      </Button>);
    };
    return (<article className={cn(props.className, styles.offerBlockQ32024)} {...createAnalyticShowAttribute('landing-showcase-offers')}>
      <h3 className={styles.title}>выбирайте подписку</h3>
      <AnchorScrollObserver defaultActiveId={'mono'} observerProps={{ rootMargin: '140px 0px' }}>
        <AnchorTabs withScrollToSelectedTab={true} scrollBehaviorToSelectedTab={'center'} variant={'sticky'} className={styles.anchorTabs}>
          {tabsList.map((tab, index) => (<AnchorTab key={tab.id} anchorId={tab.id} before={getTabIcons(tab.icon)} selected={activeTab === tab.id} defaultChecked={index === 0} onClick={() => clickSetActiveTab(tab.id)} {...createAnalyticClickAttribute(getDefaultMetrics(tab?.metrics, 'click', 'landing-showcase-offers-tab'))}>
              {tab.title}
            </AnchorTab>))}
        </AnchorTabs>
        <section className={cn(props.offerGroupListClassName, styles.offerGroupList)}>
          {groupShowCaseOfferList.map(([tabId, offers], index) => (<AnchorTabContent key={tabId} anchorId={tabId} className={styles.content}>
              {index > 0 && <h3 className={styles.title}>{tabsMap.get(tabId)?.title}</h3>}
              <ul className={styles.offerGroup}>
                {offers.map((offer) => (<OfferCardQ32024 offer={offer} button={button} className={styles.offerGroupItem} key={offer.correlationId}/>))}
              </ul>
            </AnchorTabContent>))}
        </section>
      </AnchorScrollObserver>
    </article>);
};
export default OfferBlockQ32024;
