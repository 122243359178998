import React, { useRef } from 'react';
import className from 'classnames';
import styles from './styles.module.scss';
const themeMap = {
    magenta: styles.badge__magenta,
    violet: styles.badge__violet,
    pink: styles.badge__pink,
    purple: styles.badge__purple,
    blue: styles.badge__blue,
    orange: styles.badge__orange,
};
const getTheme = (theme) => {
    if (!theme) {
        return;
    }
    const value = themeMap[theme];
    if (!value) {
        return;
    }
    return value;
};
const Badge = (props) => {
    const { children, theme } = props;
    const ref = useRef(null);
    const badgeThemeClass = getTheme(theme);
    const classNameMain = className(styles.badge, badgeThemeClass);
    return (<div ref={ref} className={classNameMain}>
      {children}
    </div>);
};
export default Badge;
