import { createEffect, createStore } from 'effector';
import { featureFlagsModel } from '@common/entities/feature-flags';
import { OFFERS_KEY_WORD_MAIN_WEBSITE, OFFERS_KEY_WORD_MAIN_WEBSITE_REDESIGN_1124, } from '@website/shared/constants/website-keywords';
import apiCommon from '@common/utils/ApiCommon';
const AB_TEST_KEY = 'offer_shelf_redesign_1124';
const FF_KEY = 'enable_offer_shelf_redesign_1124';
const $checkConditionABTestEnableResult = createStore(null);
export const checkConditionABTestEnableFx = createEffect(async () => {
    try {
        const featureFlagResponse = await featureFlagsModel.getFeatureFlagsFx({});
        const isHasFeatureFlag = Boolean(featureFlagResponse[FF_KEY]);
        if (!isHasFeatureFlag) {
            return OFFERS_KEY_WORD_MAIN_WEBSITE;
        }
        const abTestResponse = await apiCommon.getAbTestVariant(AB_TEST_KEY);
        const isNewVariant = abTestResponse.data.variant === 'new';
        return isNewVariant ? OFFERS_KEY_WORD_MAIN_WEBSITE_REDESIGN_1124 : OFFERS_KEY_WORD_MAIN_WEBSITE;
    }
    catch {
        return OFFERS_KEY_WORD_MAIN_WEBSITE;
    }
});
$checkConditionABTestEnableResult.on(checkConditionABTestEnableFx.doneData, (_, payload) => payload);
export const showcaseOffersABTestQ32024Model = { checkConditionABTestEnableFx, $checkConditionABTestEnableResult };
