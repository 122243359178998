import { createGate } from 'effector-react';
import { createEffect, sample } from 'effector';
import { callAnalyticVisit } from '@analytics';
import { OffersModel } from '../lib/offers-model';
import { OFFERS_KEY_WORD_MAIN_WEBSITE } from '@website/shared/constants/website-keywords';
import { AuthStateModel } from '@common/entities/auth-state';
import { showcaseOffersABTestQ32024Model } from './ab-test-q3-2024-model';
const MainGate = createGate({});
const sendAnalyticFx = createEffect(() => {
    callAnalyticVisit();
});
sample({ clock: MainGate.open, target: [sendAnalyticFx] });
sample({
    clock: [MainGate.open, AuthStateModel.$authState],
    source: { authState: AuthStateModel.$authState, isOpen: MainGate.status },
    filter: ({ authState, isOpen }) => (authState === 'authorized' || authState === 'unauthorized') && isOpen,
    target: showcaseOffersABTestQ32024Model.checkConditionABTestEnableFx,
});
sample({
    clock: showcaseOffersABTestQ32024Model.$checkConditionABTestEnableResult,
    fn: (keyword) => {
        const result = keyword ?? OFFERS_KEY_WORD_MAIN_WEBSITE;
        return { keywords: [result] };
    },
    target: OffersModel.getOffersFx,
});
export const MainTemplateModel = {
    MainGate,
};
