import React from 'react';
import { useIntl } from 'react-intl';
import { CustomImg } from '@common/shared/ui/custom-img';
import { messages } from '@website/template/main-template/messages';
import styles from './styles.module.scss';
import { images } from './images';
export const HeroImage = () => {
    const intl = useIntl();
    return (<CustomImg className={styles.image_hero} {...images} alt={intl.formatMessage(messages.hero_image_alt)}/>);
};
