import headphonesMobile3XWebp from './headphones-mobile@3x.webp';
import headphonesMobile3XPng from './headphones-mobile@3x.png';
import headphonesMobile2XWebp from './headphones-mobile@2x.webp';
import headphonesMobile2XPng from './headphones-mobile@2x.png';
import headphonesMobile1XWebp from './headphones-mobile@1x.webp';
import headphonesMobile1XPng from './headphones-mobile@1x.png';
export const headphonesMobileImage = {
    src: headphonesMobile1XPng.src,
    width: 343,
    height: 389,
    sources: [
        { width: 343, height: 389, srcSet: headphonesMobile3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 343, height: 389, srcSet: headphonesMobile3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 343, height: 389, srcSet: headphonesMobile2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 343, height: 389, srcSet: headphonesMobile2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 343, height: 389, srcSet: headphonesMobile1XWebp.src },
        { width: 343, height: 389, srcSet: headphonesMobile1XPng.src },
    ],
};
