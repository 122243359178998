import headphonesTable3XWebp from './headphones-table@3x.webp';
import headphonesTable3XPng from './headphones-table@3x.png';
import headphonesTable2XWebp from './headphones-table@2x.webp';
import headphonesTable2XPng from './headphones-table@2x.png';
import headphonesTable1XWebp from './headphones-table@1x.webp';
import headphonesTable1XPng from './headphones-table@1x.png';
export const headphonesTableImage = {
    src: headphonesTable1XPng.src,
    width: 648,
    height: 483,
    sources: [
        {
            width: 648,
            height: 483,
            srcSet: headphonesTable3XWebp.src,
            media: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 648,
            height: 483,
            srcSet: headphonesTable3XPng.src,
            media: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 648,
            height: 483,
            srcSet: headphonesTable2XWebp.src,
            media: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 648,
            height: 483,
            srcSet: headphonesTable2XPng.src,
            media: '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 648, height: 483, srcSet: headphonesTable1XWebp.src, media: '(min-width: 768px)' },
        { width: 648, height: 483, srcSet: headphonesTable1XPng.src, media: '(min-width: 768px)' },
    ],
};
