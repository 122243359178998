import headphonesDesktop3XWebp from './headphones-desktop@3x.webp';
import headphonesDesktop3XPng from './headphones-desktop@3x.png';
import headphonesDesktop2XWebp from './headphones-desktop@2x.webp';
import headphonesDesktop2XPng from './headphones-desktop@2x.png';
import headphonesDesktop1XWebp from './headphones-desktop@1x.webp';
import headphonesDesktop1XPng from './headphones-desktop@1x.png';
export const headphonesDesktopImage = {
    src: headphonesDesktop1XPng.src,
    width: 565,
    height: 669,
    sources: [
        {
            width: 565,
            height: 669,
            srcSet: headphonesDesktop3XWebp.src,
            media: '(min-width: 1280px) and (-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 565,
            height: 669,
            srcSet: headphonesDesktop3XPng.src,
            media: '(min-width: 1280px) and (-webkit-min-device-pixel-ratio: 3)',
        },
        {
            width: 565,
            height: 669,
            srcSet: headphonesDesktop2XWebp.src,
            media: '(min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        {
            width: 565,
            height: 669,
            srcSet: headphonesDesktop2XPng.src,
            media: '(min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2)',
        },
        { width: 565, height: 669, srcSet: headphonesDesktop1XWebp.src, media: '(min-width: 1280px)' },
        { width: 565, height: 669, srcSet: headphonesDesktop1XPng.src, media: '(min-width: 1280px)' },
    ],
};
