import { Icon90Education, Icon90ForFour, Icon90ForTwo, Icon90Music, Icon90Music12, Icon90Wink, } from '@common/shared/ui/icons';
const mapIcon = {
    icon_education_90: Icon90Education,
    icon_wink_90: Icon90Wink,
    icon_group_for_four_90: Icon90ForFour,
    icon_group_for_two_90: Icon90ForTwo,
    icon_music_90: Icon90Music,
    icon_music_12_90: Icon90Music12,
};
export const getIcon = (iconType) => {
    if (!iconType) {
        return null;
    }
    const icon = mapIcon[iconType];
    return icon ?? null;
};
